'use client';

import React, { useEffect } from 'react';
import { triggerCookieManager } from '@/util/triggerCookieManager';

export function CookieManagementTrigger(): JSX.Element {
  useEffect(() => {
    const triggers = document.querySelectorAll('a[href="#cookie-settings"]');
    if (triggers.length) {
      triggers.forEach((trigger) => {
        trigger.addEventListener('click', triggerCookieManager);
      });
      return () => triggers.forEach((trigger) => trigger.removeEventListener('click', triggerCookieManager));
    }
    return undefined;
  });

  return <span />;
}
